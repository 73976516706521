<template>
  <div class='demo-app'>
    <div class='demo-app-top'>
      <!--<button @click="toggleWeekends">toggle weekends</button>
      <button @click="gotoPast">go to a date in the past</button>
      (also, click a date/time to add an event) -->
      <a href="/admin/events-list/">Events</a>
    </div>

    <!--  <form>

<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <label for="fromDate">Event Name:</label>
      <input type="text" class="form-control" placeholder="Enter event" id="eventName">
    </div>
  </div>
</div>

<div class="row"> 
  <div class="col-md-6">
    <div class="form-group">
      <label for="fromDate">From:</label>
      <input type="date" class="form-control" placeholder="Enter from date" id="fromDate">
    </div>
  </div>
  
  <div class="col-md-6">
    <div class="form-group">
      <label for="toDate">To:</label>
      <input type="date" class="form-control" id="toDate">
    </div>
  </div>

</div>

<button type="button" class="btn btn-primary" id="addEvent">Add Event</button>

</form>
-->
    <modal :show.sync="showResultsModal" modal-classes="modal-secondary" size="lg">
      <tr>
        <td>
          <div>
            <button v-on:click='onAdd' v-if="!currentId">Add</button>
            <button v-on:click='onEdit' v-if="currentId">edit</button>

          </div>
        </td>

      </tr><br>
      <form @submit.prevent="addEvent">
        <label>Title:</label>
        <input v-model="eventTitle" required>
        <br>
        <label>Date de début:</label>
        <input type="datetime-local" v-model="eventStart" required>
        <br>
        <br>
        <label>Date de fin:</label>
        <input type="datetime-local" v-model="eventEnd" required>
        <br>
        <label>Occurence:</label>
        <select v-model="eventRepeted">
          <option disabled value="">Please select one</option>
          <option value="0">Tous les jours</option>
          <option value="1">Tous les semaines</option>
        </select>



      </form>

    </modal>

    <modal name="show-modal" :show.sync="showVModal" modal-classes="modal-secondary" size="lg">
      <tr>

        <td>
          <div>
            <button @click='editEventModal()'>Edit</button>
          </div>
        </td>
        <td>
          <div>
            <button @click="deleteEvent()">Delete</button>

          </div>
        </td>
      </tr><br>
      Titre : {{ eventTitle }}
      <br>
      Date de début : {{ eventStart }}
      <br>
      Date de fin : {{ eventEnd }}

    </modal>


    <FullCalendar class='demo-app-calendar' ref="fullCalendar" defaultView="dayGridMonth" :header="{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    }" :plugins="calendarPlugins" :weekends="calendarWeekends" :events="this.events" @dateClick="editModal"
      @select="" :eventsSet="handleEvents" @eventClick="showModal" />
  </div>

</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { cloneDeep } from "lodash";
import defaultEvent from "./defaultEvent";
import Custom from "./Custom";
import {
  addDays,
  subDays,
  isBefore,
  isAfter,
  eachDay,
  format,
  addMonths,
  getYear,
  setYear,
  isWithinRange
} from "date-fns";
import swal from "sweetalert2";

export default {
  components: {
    FullCalendar,
    // make the <FullCalendar> tag available
  },
  data: function () {
    return {
      calendarPlugins: [ // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      calendarWeekends: true,
      selectable: true,
      calendarEvents: [
        {
          groupId: 'blueEvents', // recurrent events in this group move together
          daysOfWeek: ['2', '4'],
          startTime: '10:45:00',
          endTime: '12:45:00'
        },
        {
          daysOfWeek: ['3'], // these recurrent events move separately
          startTime: '11:00:00',
          endTime: '11:30:00',
          color: 'red'
        },
        // initial event data
        { title: 'Event Now', start: new Date() }
      ],

      events: [],
      selectedDate: "",

      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      loading: true,
      query: null,
      selectedRows: [],
      eventTitle: '',
      eventStart: '',
      eventEnd: '',
      eventRepeted: 'Month',
      showResultsModal: false,
      showVModal: false,
      calendar: '',
      currentId: null,

    }
  },
  computed: {
  },
  methods: {
    async deleteEvent(Event) {
      console.log(Event)
      const confirmation = await swal.fire({
        title: this.$t("EVENTS.DELETE_THIS_EVENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("events/destroy", this.currentId);
          this.showVModal = false;

          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENT_DELETED"),
          });
          this.events = this.events.filter(event => event.id !== this.currentId);


        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async onEdit(arg) {

let eventData = {
  name: this.eventTitle,
  start_at: this.eventStart,
  end_at: this.eventEnd,
  recurrent: this.eventRepeted,
  id: this.currentId,
  //startRecur: this.eventStart,
  //endRecur: this.eventStart,

  type: "events",
  color: 'red'
}
try {
  await this.$store.dispatch("events/update", eventData);
  this.$notify({
    type: "success",
    message: this.$t("Events.Events_ADDED"),
  });
  /*const event = await this.$store.getters[
    "event/event"
  ];*/

  this.loading = false;
} catch (error) {
  console.log(error)
  this.$notify({
    type: "danger",
    message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
  });

}

this.showResultsModal = false;
//window.location.reload();
},
    async onAdd(arg) {

      console.log("rep",this.eventRepeted);


      let currentDate = new Date(this.eventStart)
      let nextDate = new Date(currentDate)
      nextDate.setDate(currentDate.getDate() + 1)

      let currentDay = [0, 1, 2, 3, 4, 5, 6];

      if (this.eventRepeted == 1) {
        currentDay = [currentDate.getDay()];

        nextDate = new Date(currentDate)
        nextDate.setDate(currentDate.getDate() + 7)
      }


      console.log("start", this.eventStart)
      console.log("end", nextDate)
      console.log("days", this.getDaysOfWeek(this.eventStart, nextDate.toISOString()));

      let calendarApi = this.calendar
      calendarApi.unselect() // clear date selection
      let randomColor = Math.floor(Math.random() * 16777215).toString(16);

      calendarApi.addEvent({
        title: this.eventTitle,
        start: this.eventStart,
        end: this.eventStart,
        daysOfWeek: currentDay,
        color: '#' + randomColor,
        startRecur: this.eventStart,
        endRecur: this.eventEnd,
      })

      let eventData = {
        name: this.eventTitle,
        start_at: this.eventStart,
        end_at: this.eventEnd,
        recurrent: this.eventRepeted,
        //startRecur: this.eventStart,
        //endRecur: this.eventStart,

        type: "events",
        color: 'red'
      }
      try {
        await this.$store.dispatch("events/add", eventData);
        this.$notify({
          type: "success",
          message: this.$t("Events.Events_ADDED"),
        });
        /*const event = await this.$store.getters[
          "event/event"
        ];*/
        const obj = JSON.parse(event.target.response);

        console.log("aaa", obj.data.id)
        this.$emit("onViewEvent", event, true);
        this.loading = false;
      } catch (error) {
        console.log(error)
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

      }

      this.showResultsModal = false;
      //window.location.reload();
    },
    showModal(arg) {
      console.log("show", arg.event)
      this.eventTitle = arg.event._def.title;
      this.eventStart = arg.event._def.extendedProps.start;
      this.eventEnd = arg.event._def.extendedProps.end;
      this.currentId = arg.event._def.publicId;
console.log("id",this.currentId)
      this.showVModal = true;

      /* let options = {
         name: "test",
         adaptive: true,
         height: "auto"
       };
       const props = {
         modelData: "data"
       };
       this.$modal.show(Custom, props, options);
 
       setTimeout(() => {
         this.testing = "This value should be shown in modal";
       }, 1000);
       */
    },
    show() {
      this.$modal.show('my-first-modal');
    },
    hide() {
      this.$modal.hide('my-first-modal');
    },
    toggleWeekends() {
      this.calendarWeekends = !this.calendarWeekends // update a property
    },
    gotoPast() {
      let calendarApi = this.$refs.fullCalendar.getApi() // from the ref="..."
      calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
    },
    async handleDateClick(arg) {
      //this.$modal.show();
      this.$modal.show('event-modal');
      if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
        this.calendarEvents.push({ // add new event data
          title: 'New Event',
          start: arg.date,
          allDay: arg.allDay
        })
        let event = { // add new event data
          name: 'New Event',
          type: "events",
        }
        const eventData = cloneDeep(event);

        //console.log(event)
        try {
          let ev = await this.$store.dispatch("events/add", eventData);
          console.log("add", ev)
          this.$notify({
            type: "success",
            message: this.$t("Events.Events_ADDED"),
          });
          /*const event = await this.$store.getters[
            "event/event"
          ];*/
          this.$emit("onViewEvent", event, true);
          this.loading = false;
        } catch (error) {
          console.log(error)
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });

        }
      }
    },

    addEvent(event) {
    },
    getDaysOfWeek(startDate, endDate) {
      const daysOfWeek = [];
      const currentDate = new Date(startDate);

      while (currentDate < new Date(endDate)) {
        console.log("ssss")

        const day = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        if (!daysOfWeek.includes(day)) {
          daysOfWeek.push(day);
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Sort the days for better readability (optional)
      daysOfWeek.sort((a, b) => a - b);

      return daysOfWeek;
    },
    async editEventModal() {
      
      if (this.currentId) {
          // Update event
          const updatedEvent = {
            title: this.eventTitle,
            start: this.eventStart,
            end: this.eventEnd,
            recurrent: this.eventRepeted,
          };
       

        }
        this.showResultsModal = true;
      this.showVModal = false;
      console.log("up",this.currentId);

    },
    async editModal(selectInfo) {
      this.calendar = selectInfo.view.context.calendar;

 
      // let nextDate = new Date(selectInfo.dateStr)
      let next = selectInfo.dateStr;
      let nextDate = new Date(selectInfo.dateStr)
      const formattedDate = nextDate.toISOString().slice(0, 16);

      this.eventStart = formattedDate;

      this.eventEnd = formattedDate;

      this.showResultsModal = true;
      console.log("ev", formattedDate)
      this.currentId = null;


    }
    ,
    async handleDateSelect(selectInfo) {
      this.eventStart = selectInfo.startStr;
      console.log("selected", selectInfo.startStr)
      this.showResultsModal = true;

      //let title = prompt('Please enter a new title for your event')
      let title = "Event title";
      let calendarApi = selectInfo.view.context.calendar
      calendarApi.unselect() // clear date selection
      let randomColor = Math.floor(Math.random() * 16777215).toString(16);
      if (true) {
        calendarApi.addEvent({
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
          daysOfWeek: this.getDaysOfWeek(selectInfo.startStr, selectInfo.endStr),
          color: '#' + randomColor
        })

        let eventData = {
          name: title,
          start_at: selectInfo.startStr,
          end_at: selectInfo.endStr,
          type: "events",
          color: 'red'
        }
        try {
          await this.$store.dispatch("events/add", eventData);
          this.$notify({
            type: "success",
            message: this.$t("Events.Events_ADDED"),
          });
          /*const event = await this.$store.getters[
            "event/event"
          ];*/
          this.$emit("onViewEvent", event, true);
          this.loading = false;
        } catch (error) {
          console.log(error)
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });

        }
      }
    },
    handleEvents(events) {
      this.events = events
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          // include: "owner,reseller",
        };


        await this.$store.dispatch("events/list", params);
        this.events = this.$store.getters["events/list"];
        this.total = this.$store.getters["events/listTotal"];

        this.events = this.events.map(event => {

          let currentDate = new Date(event.start_at)
          let nextDate = new Date(currentDate)
          nextDate.setDate(currentDate.getDate() + 1)

          //let currentDay = currentDate.getDay();
          let currentDay = [0, 1, 2, 3, 4, 5, 6];
console.log("reep", event)
          if (event.recurrent == 1) {
            currentDay = [currentDate.getDay()];

            nextDate = new Date(currentDate)
            nextDate.setDate(currentDate.getDate() + 7)
          }
          let randomColor = Math.floor(Math.random() * 16777215).toString(16);



          return {
            title: event.name,
            start: event.start_at,
            end: event.end_at,
            daysOfWeek: currentDay,
            startRecur: event.start_at,
            endRecur: event.end_at,
            color: '#' + randomColor,
            id: event.id
          }
        });


        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
  async mounted() {
    this.getList();
    //this.show();
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    // selectedReseller: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
  },
}

</script>

<style lang='scss'>
// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

/*.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: 0 auto;
  max-width: 900px;
}
*/
</style>